import React from "react";
//PRIMEREACT
import { Image } from "primereact/image";
//ESTILOS
import "./Layout.css";
//MEDIA
import BrillaLogo from "../../assets/images/brilla.png";
import SurtigasLogo from "../../assets/images/surtigas.svg";
import TitanesLogo from "../../assets/images/titanes.png";
//Componentes
import BtnPiter from "../BtnPiter";
import Surveys from "../Surveys";
import AudioItemn from "../../components/AudioItemn";

function Footer({ configurations }) {
  return (
    <>
      <footer className='grid footer sinM align-items-center'>
        <div className='col-12 md:col-6'>
          <p className='text-4xl sinM'>¿Tienes alguna duda?</p>
          <p className='text-ms sinM'>
            <a href='mailto:Fidelizacion.brilla@Surtigas.co'>Ingresa aquí</a>
          </p>
          <br />
        </div>
        <div className='col-12 md:col-6 '>
          <div className='grid align-items-center text-center'>
            <div className='col-4 sinP'>
              <Image
                src={BrillaLogo}
                alt='Brilla'
              />
            </div>
            <div className='col-4 sinP'>
              <Image
                src={SurtigasLogo}
                alt='Surtigas'
              />
            </div>
            <div className='col-4 sinP'>
              <Image
                src={TitanesLogo}
                alt='Titanes'
              />
            </div>
          </div>
        </div>
        <div className='col-12'>
          <p className='copy text-xs md:text-sm sinM'>
            <a
              href={configurations.habeasData}
              target='_blank'
              rel='noreferrer'
              className='linkDoc'>
              Política de tratamiento de la información y datos personales -
              Políticas de cookies
            </a>{" "}
            y{" "}
            <a
              href={configurations.termsAndConditions}
              target='_blank'
              rel='noreferrer'
              className='linkDoc'>
              Terminos y condiciones.
            </a>
          </p>
          <p className='copy text-xs md:text-sm sinM'>
            © Todos los Derechos Reservados - Brilla - Colombia
          </p>
        </div>
      </footer>
      <AudioItemn />
      <Surveys />
      <BtnPiter />
    </>
  );
}

export default Footer;
