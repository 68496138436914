import React, { useState } from "react";
import { toast } from "react-toastify";
//SERVICIOS
import fglobales from "../../services/fglobales";

function ChargeImg({ dataUser }) {
  const [submitted, setSubmitted] = useState(false);
  const [imgFachada, setImgFachada] = useState(null);
  const [imgFachadaSrc, setImgFachadaSrc] = useState(
    dataUser.ecologicoImg ? dataUser.ecologicoImg : null
  );
  const [imgInside, setImgInside] = useState(null);
  const [imgInsideSrc, setImgInsideSrc] = useState(
    dataUser.explicacionImg ? dataUser.explicacionImg : null
  );
  const [viewBtn, setViewBtn] = useState("0");

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const imgPremio = files[0];

    const fileTypes = ["image/jpeg", "image/png"];
    if (!fileTypes.includes(imgPremio.type)) {
      toast.error("Subir fotos .jpeg o .png");
      return;
    }

    if (imgPremio.size > 20 * 1024 * 1024) {
      toast.error("Imagen demasiado grande:", imgPremio.size);
      return;
    }

    setImgFachada(imgPremio);
    setImgFachadaSrc(URL.createObjectURL(imgPremio));
  };

  const handleFileChangeInterior = (event) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const imgPremio = files[0];

    const fileTypes = ["image/jpeg", "image/png"];
    if (!fileTypes.includes(imgPremio.type)) {
      toast.error("Subir fotos .jpeg o .png");
      return;
    }

    if (imgPremio.size > 20 * 1024 * 1024) {
      toast.error("Imagen demasiado grande:", imgPremio.size);
      return;
    }

    setImgInside(imgPremio);
    setImgInsideSrc(URL.createObjectURL(imgPremio));
  };

  const updateUserImg = async (dataImg, campoImg, view) => {
    setSubmitted(true);
    const addSucursalImg = await fglobales.addSucursalImg(
      "usersClients",
      dataUser,
      dataImg,
      campoImg
    );

    toast.success(addSucursalImg.message);
    setSubmitted(false);
    setViewBtn(view);
  };

  return (
    <>
      <div className='col-12 md:col-10 md:col-offset-1 mt-4 contentForm'>
        <div className='grid'>
          <div className='col-12 md:col-10 md:col-offset-1'>
            <h2 className='mt-0 text-center'>
              <b>Cargar foto plataforma</b>
            </h2>
          </div>

          <div className='col-12 md:col-10 md:col-offset-1'>
            <div className='grid'>
              {/*Fachada*/}
              <div className='col-12 md:col-6'>
                <div className='docImgL'>
                  <div className='boxImg'>
                    {imgFachadaSrc && (
                      <img
                        src={imgFachadaSrc}
                        alt='Fachada'
                      />
                    )}
                  </div>

                  {dataUser.ecologicoImg ||
                  viewBtn === "1" ||
                  viewBtn === "2" ? (
                    <div className='col-12'>
                      <p className='adjuntoProfile'>Foto del punto ecológico</p>
                    </div>
                  ) : (
                    <div className='boxInfo'>
                      <div className='grid'>
                        {imgFachadaSrc ? (
                          <>
                            <div className='col-6'>
                              <label>
                                <p className='adjuntoProfile'>
                                  Editar <i className='pi pi-pencil'></i>
                                </p>
                                <input
                                  type='file'
                                  accept='image/jpeg, image/png'
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                />
                              </label>
                            </div>

                            <div className='col-6'>
                              <p
                                className='adjuntoProfile'
                                onClick={() => {
                                  updateUserImg(
                                    imgFachada,
                                    "ecologicoImg",
                                    "1"
                                  );
                                }}>
                                Subir <i className='pi pi-cloud-upload'></i>
                              </p>
                            </div>
                          </>
                        ) : (
                          <div className='col-12'>
                            <br />
                            <label className='p-button-brilla p-button-sucursal'>
                              Carga la foto de tu punto ecológico
                              <input
                                type='file'
                                accept='image/jpeg, image/png'
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/*Interior */}
              <div className='col-12 md:col-6'>
                <div className='docImgL'>
                  <div className='boxImg'>
                    {imgInsideSrc && (
                      <img
                        src={imgInsideSrc}
                        alt='Fachada'
                      />
                    )}
                  </div>

                  {dataUser.explicacionImg || viewBtn === "2" ? (
                    <div className='col-12'>
                      <p className='adjuntoProfile'>Foto de la explicación</p>
                    </div>
                  ) : (
                    <div className='boxInfo'>
                      <div className='grid'>
                        {imgInsideSrc ? (
                          <>
                            <div className='col-6'>
                              <label>
                                <p className='adjuntoProfile'>
                                  Editar <i className='pi pi-pencil'></i>
                                </p>
                                <input
                                  type='file'
                                  accept='image/jpeg, image/png'
                                  style={{ display: "none" }}
                                  onChange={handleFileChangeInterior}
                                />
                              </label>
                            </div>

                            <div className='col-6'>
                              {!submitted ? (
                                <p
                                  className='adjuntoProfile'
                                  onClick={() => {
                                    updateUserImg(
                                      imgInside,
                                      "explicacionImg",
                                      "2"
                                    );
                                  }}>
                                  Subir <i className='pi pi-cloud-upload'></i>
                                </p>
                              ) : (
                                <p className='adjuntoProfileDisable'>
                                  Subir <i className='pi pi-cloud-upload'></i>
                                </p>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className='col-12'>
                            <br />
                            <label className='p-button-brilla p-button-sucursal'>
                              Carga la foto de la explicación
                              <input
                                type='file'
                                accept='image/jpeg, image/png'
                                style={{ display: "none" }}
                                onChange={handleFileChangeInterior}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChargeImg;
