import React, { useEffect, useRef } from "react";
import navidad from "../assets/audio/JingleBells.mp3";

const AutoPlayAudio = () => {
  const audioRef = useRef(null);
  const retryTimeout = useRef(null);

  useEffect(() => {
    const playAudio = () => {
      if (audioRef.current) {
        audioRef.current.volume = 0.2; // Configura el volumen al 20%
        audioRef.current
          .play()
          .then(() => console.log("Audio reproduciéndose"))
          .catch((err) => {
            console.log("Error al reproducir el audio:", err);
            // Si falla, habilitar el clic nuevamente después de unos segundos
            retryTimeout.current = setTimeout(() => {
              document.addEventListener("click", handleUserInteraction);
              console.log("Reactivado para detectar clics");
            }, 5000); // 5 segundos de espera
          });
      }
    };

    const handleUserInteraction = () => {
      playAudio();
      // Elimina los listeners de scroll y clic después de la primera interacción exitosa
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("scroll", handleUserInteraction);
    };

    // Agrega listeners de clic y scroll
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("scroll", handleUserInteraction);

    return () => {
      // Limpia los listeners y los temporizadores al desmontar
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("scroll", handleUserInteraction);
      if (retryTimeout.current) {
        clearTimeout(retryTimeout.current);
      }
    };
  }, []);

  return (
    <div>
      <audio
        ref={audioRef}
        src={navidad}
        preload='auto'
      />
    </div>
  );
};

export default AutoPlayAudio;
